/* Custom css index */
.bg {
    position: absolute;
    background-image: url("../img/Norheimsundbilde.jpg");
    
    background-repeat: no-repeat;
    
    background-size:cover;
    height: 0px;
    width: 100%;
    z-index: -9999;
    padding-top: 56.24%;    /* Img-height / Img-width * container-width -> 1406 / 2500 * 100 (%) */ 
    margin-top: -7%;
    min-height: 1200px;
    
}


.content_page {

    background-color: rgb(247, 247, 247);
    
    margin-top: 0px;
    height: 100%;
    position: absolute; 
    visibility: hidden;
    opacity: 0;
    width: 100%;
    
    transition: opacity  0.7s cubic-bezier(0.17, 0.59, 0.61, 1.02), margin-top  0.7s cubic-bezier(0.17, 0.59, 0.61, 1.02),visibility 0s linear,position 0s linear;
    
}

.grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, 450px);
    
    gap: 5rem;
    justify-content: center;
}

.grid_obj {
    background-color: rgb(233, 232, 232);
    display: block;
    text-align: center;
    max-height: 470px;
    max-width: 450px;
    border-radius: 4px;
    font-weight: 600;
    font-size: 18px;
}
.img_container {
    border-radius: 4px;
    width: 94%;
    background-size: cover;
    margin: 12px auto 4px auto;
}
.img_container:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
.img1 {background-image: url("../img/tolohagen.jpg");}
.img2 {background-image: url("../img/tolotunet.jpg");}
.img3 {
    
    width: 100%;
    border-radius: 4px;
}

.grid_obj p {
    margin: 8px;
}



@media only screen and (max-width: 600px) {
    .grid {
        
        grid-template-columns: repeat(auto-fit, 300px);
        
    }
}