
.vedtekter {
    padding: 130px 0;

}
.split{
    margin: 20px 0;
}
.content_wrap {
    width: 50%;
    margin: 0 25%;
}
.content_item {
    padding: 25px 0;
}





p, ol, li{
    line-height: 25px;
    font-size: max(1vw, 16px);
    color: rgb(74, 74, 74);
    font-weight: 400;
}
li {
    margin: 15px 0;
    font: max(1vw, 16px);
}
p {
    margin: 3px 0;
    font-size: max(1vw, 16px);
}
ol {
    padding: 15px 0 15px 25px;
}
h1 {
    font-weight: 500;
    font-size: max(1.4vw, 20px);
    line-height: 30px;
    margin: 7px 0;
    color: rgb(69, 69, 69);
}





@media only screen and (max-width: 850px) {
    .content_wrap {
        width: 80%;
        margin: 0 10%;
    }
    p, ol, li {
        font-weight: 300;
    }
}