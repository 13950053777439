.content_page {
    padding: 80px 0;
}
h1 {
    font-size: max(1.7vw, 18px);
    font-weight: 600;
    margin: 12px 0;
}
p,a {
    font-size: max(1vw, 14px);
    
    margin: 9px 0;
}


.grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, 600px);
    
    gap: 3rem;
    justify-content: center;
}
.grid_obj {
    background-color: rgb(233, 232, 232);
    display: block;
    text-align: center;
    
    
    border-radius: 4px;
    font-weight: 600;
    font-size: 18px;
}
.img_container {
    border-radius: 4px;
    width: 94%;
    background-size: cover;
    margin: 12px auto 4px auto;
}
.img_container:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
