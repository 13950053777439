.content_page {
    padding: 80px 0;
}
h1 {
    font-size: max(1.7vw, 18px);
    font-weight: 600;
    margin: 12px 0;
}
p,a {
    font-size: max(1vw, 14px);
    
    margin: 9px 0;
}
.mail:-webkit-any-link {
    color: -webkit-link;
    cursor: pointer;
    text-decoration: underline;
}