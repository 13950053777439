.this_header {
    height: 160px;
    
    padding-left: 30px;
}
.this_header div {
    
    height: 100%;
    padding-top: 120px;
}

.content_item {
    padding: 20px 0;
}
.btn_list {
    margin: 10px 0;
    display: flex;
}

.btn_list button {
    margin: 5px 5px;
    max-height: 80px;
    max-width: 250px;
    padding: 20px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 4px;
    border-width: 2px;
    border-color: rgb(60,60,60);

    background-color: rgb(247,247,247);
    transition: background-color 0.3s;
}

.btn_list button:hover {
    background-color: rgba(211, 211, 211, 0.766);
}
.btn_list button a {
    font-size: 16px;
    font-weight: 600;
}